import { TestResultType, CodeItemType } from "../../../types/TestResult.type";
import { ResultMaskType } from "../../../types/ResultMask.type";
import { Icon } from "@iconify/react";

import moment from "moment";

import ResultCardMasks from "./ResultCardMasks";

const ResultCard = ({
  result,
  deleteItemHandler,
  maskList,
  getBindCode,
}: {
  result: TestResultType;
  deleteItemHandler: (id: string) => void;
  getBindCode: (code: string) => string;
  maskList: ResultMaskType[];
}) => {
  return (
    <div className="border border-gray-200 rounded-lg overflow-hidden p-4">
      <div className=" mx-2 flex justify-between items-center">
        <p className="text-md text-gray-500">
          {result.mode} ID: <span className="font-bold">{result._id}</span>
        </p>
        <button
          onClick={() => deleteItemHandler(result._id)}
          className="btn btn-square btn-sm btn-outline btn-error"
        >
          <Icon fontSize="26px" icon="mdi:trash-outline" />
        </button>
      </div>
      <div className="px-4 py-2">
        <AboutSection data={result} />
        {result.name && (
          <p className="text-accent text-lg font-bold">{result.name}</p>
        )}
        <CodeSection data={result} getBindCode={getBindCode} />
      </div>
      <TableSection getBindCode={getBindCode} data={result} />
      <ResultCardMasks codes={result.codes} maskList={maskList} />
    </div>
  );
};

const AboutSection = ({ data }: { data: TestResultType }) => {
  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-4">
      <Field name="Пол" value={getSexName(data.user.sex)} />
      <Field name="Статус" value={getStatusName(data.user.status)} />
      <Field name="Домен" value={data?.domain} />
      <Field name="Email" value={data?.user?.email} />
      <Field name="Имя" value={data?.user?.name} />
      <Field name="Город" value={data?.user?.city} />
      <Field name="Страна" value={data?.user?.country} />
      <Field name="Телефон" value={data?.user?.phone || "-"} />
      <Field
        name="Дата создания"
        value={moment(data?.createdAt).format("HH:mm DD.MM.YYYY")}
      />
    </div>
  );
};

const CodeSection = ({
  data,
  getBindCode,
}: {
  data: TestResultType;
  getBindCode: (code: string) => string;
}) => {
  return (
    <div className="mt-4">
      {data.codes.map((item: CodeItemType) => {
        return (
          <CodeItemInfo
            key={item.code}
            code={getBindCode(item.code)}
            data={item}
          />
        );
      })}
    </div>
  );
};

function CodeItemInfo({ code, data }: { code: string; data: CodeItemType }) {
  return (
    <span
      style={{
        backgroundColor: data.status,
        // backgroundColor: getBackgroundStyle(data.status, data.count),
        color: "white",
        borderRadius: "5px",
        padding: "5px",
        marginRight: "8px",
      }}
    >
      {code} - {data.value} ({data.count}-{data.positive}-{data.negative}){" "}
      {`K (${data.crucial}-${getFormatValue(
        data.positiveCrucial
      )}-${getFormatValue(data.negativeCrucial)})`}
    </span>
  );
}

const TableSection = ({
  getBindCode,
  data,
}: {
  getBindCode: (code: string) => string;
  data: TestResultType;
}) => {
  return (
    <div className="collapse collapse-arrow border border-gray-200 p-1 mt-2">
      <input type="checkbox" />
      <div className="collapse-title text-lg font-medium">Черты</div>

      <div className="collapse-content">
        <div className="overflow-x-auto">
          <table className="table">
            <thead>
              <tr>
                <th>Название</th>
                <th>Коэфициент</th>
                <th>Позитив</th>
                <th>Негатив</th>
                <th>Результат</th>
                <th>Код позитива</th>
                <th>Код негатива</th>
              </tr>
            </thead>
            <tbody>
              {data.traits.map((t: any) => {
                let exist =
                  isNumber(t.positiveValue) && isNumber(t.negativeValue);
                let isPositive = t.positiveValue >= t.negativeValue;

                return (
                  <tr>
                    <td>{t.name}</td>
                    <td className="text-success ">{t.coeff}</td>
                    <td className="text-success">{t.positiveValue}</td>
                    <td className="text-error">{t.negativeValue}</td>
                    {exist ? (
                      <td
                        className={isPositive ? "text-success" : "text-error"}
                      >
                        {isPositive ? t.value : -t.value}
                      </td>
                    ) : (
                      <td className="text-success">{t.value}</td>
                    )}

                    <td className="text-success">
                      {getBindCode(t.positiveModeCode)}
                    </td>
                    <td className="text-error">
                      {getBindCode(t.negativeModeCode)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Field = ({ name = "", value = "" }) => {
  return (
    <div>
      <p className="text-secondary-content text-xs">{name}</p>
      <p className="text-accent text-sm font-bold">{value}</p>
    </div>
  );
};

const getFormatValue = (val: any) => {
  if (typeof val == "number") {
    return val;
  } else {
    return "";
  }
};

function isNumber(val: number) {
  return !isNaN(val);
}

const getBackgroundStyle = (status: string, count: number) => {
  if (status) {
    switch (status) {
      case "lie":
        return "red";
      case "almostlie":
        return "orange";
      case "truth":
        return "purple";
      case "doubt":
        return "green";
      default:
        return "black";
    }
  }

  if (count >= 0) {
    if (count <= 2) {
      return "red";
    } else if (count <= 3) {
      return "orange";
    } else if (count > 3) {
      return "green";
    } else {
      return "black";
    }
  }
};

function getSexName(s: string) {
  switch (s) {
    case "male":
      return "Мужчина";
    case "female":
      return "Женщина";
  }
}

function getStatusName(s: string) {
  switch (s) {
    case "student":
      return "Студент";
    case "search-work":
      return "Ищет работу";
    case "work":
      return "Работает,но ищет новую работу";
  }
}

export default ResultCard;
