import { useEffect, useState } from "react";
import { SettingsType } from "../../../types/Settings.type";
import { LangType, langList } from "./сonstants";
import axios from "axios";
import { CurrentModeType } from "../../../types/CurrentMode.type";
import { toast } from "react-toastify";

const LanguageAndMode = ({ settings, settingId, setSettingId, lang, setLang }: { settings: SettingsType[], settingId: string | null, setSettingId: (id: string | null) => void, lang: LangType, setLang: (lang: LangType) => void }) => {
    const [currentMode, setCurrentMode] = useState<CurrentModeType | null>(null);
    useEffect(() => {
        axios.get("/current-mode").then(({ data }) => {
            setCurrentMode(data);
        });
    }, []);

    const changeCurrentMode = (mode: string) => {
        axios.post("/current-mode", { mode }).then(() => {
            setCurrentMode(prev => prev ? { ...prev, mode } : null);
            toast.success('Режим вопросов изменен');
        });
    };


    return <div className=" mb-6">
        <div className="">
            <div className="label">
                <span className="text-xl">Текущий режим вопросов</span>
            </div>
            <select
                className="select select-md select-primary w-full max-w-xs"
                value={currentMode?.mode || ''}
                onChange={(e) => changeCurrentMode(e.target.value)}
            >
                {settings.map((setting) => (
                    <option key={setting._id} value={setting.mode}>{setting.mode}</option>
                ))}
            </select>
        </div>

        <div className="flex justify-between items-center">
            <div>
                {settings.map((setting) => (
                    <button key={setting._id} className={`${settingId == setting._id ? 'btn-primary' : 'btn-outline'} btn btn-sm mr-2`} onClick={() => setSettingId(setting._id)}>{setting.mode}</button>
                ))}
            </div>
            <div className="flex flex-col items-end">
                <div className="label text-xl text-right">
                    Язык
                </div>
                <select
                    className="select select-md select-primary w-full max-w-xs"
                    value={lang}
                    onChange={(e) => setLang(e.target.value as LangType)}
                >
                    {langList.map((lang) => (
                        <option key={lang} value={lang}>{lang.toUpperCase()}</option>
                    ))}
                </select>
            </div>
        </div>

    </div>;
};

export default LanguageAndMode;