import { TraitInterface, TraitMoodInterface } from "./types";

export default function formattedMapTrait() {
  return (t: TraitInterface): TraitMoodInterface => {
    return {
      _id: t._id,
      rus: t.rus,
      geo: t.geo,
      eng: t.eng,
      score: 0,
      hide: false,
      modeCode: t.modeCode,
      cycle: undefined,
    };
  };
}
