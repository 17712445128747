
import { LangType } from "./сonstants";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";

type MoodType = "positive" | "negative";
type UpperLangType = "ENG" | "GEO" | "RUS";
type QuestionKey = `${MoodType}${UpperLangType}`;

interface QuestionInterface {
    negativeENG: string;
    negativeGEO: string;
    negativeRUS: string;
    positiveENG: string;
    positiveGEO: string;
    positiveRUS: string;
}

const Questions = ({ lang }: { lang: LangType }) => {
    const [question, setQuestion] = useState<null | QuestionInterface>(null);

    useEffect(() => {
        axios.get("/question").then(({ data }: { data: QuestionInterface }) => {
            setQuestion(data);
        });
    }, []);

    const saveHandler = () => {
        if (typeof question == "object" && question !== null) {
            axios.put("/question", { ...question }).then(() => {
                toast.success("Вопросы измененны!");
            });
        }
    };

    const changeValue = (value: string, mood: MoodType) => {
        const key: QuestionKey = `${mood}${lang.toUpperCase()}` as QuestionKey; // Явное указание типа индекса
        setQuestion((prev: any) => (prev ? { ...prev, [key]: value } : prev));
    };

    if (!question) return null;

    const positiveKey: QuestionKey =
        `positive${lang.toUpperCase()}` as QuestionKey;
    const negativeKey: QuestionKey =
        `negative${lang.toUpperCase()}` as QuestionKey;

    const positiveValue = question[positiveKey];
    const negativeValue = question[negativeKey];
    return (
        <div className="mt-4">
            <p className="text-xl">Вопросы</p>
            <div className="grid sm:grid-cols-1 md:grid-cols-2">
                <div className="pr-2">
                    <div className="label">
                        <span className="label-text">Негатив</span>
                    </div>
                    <input
                        type="text"
                        value={negativeValue}
                        onChange={(e) => changeValue(e.target.value, "negative")}
                        className="input w-full flex-1 input-sm flex-1 input-bordered"
                    />
                </div>
                <div className="pl-2">
                    <div className="label">
                        <span className="label-text">Позитив</span>
                    </div>
                    <input
                        type="text"
                        value={positiveValue}
                        onChange={(e) => changeValue(e.target.value, "positive")}
                        className="input w-full flex-1 input-sm flex-1 input-bordered"
                    />
                </div>
            </div>
            <div className="flex justify-end mt-4">
                <button
                    onClick={saveHandler}
                    className="btn btn-primary btn-outline"
                >
                    Сохранить
                </button>
            </div>
        </div>
    );
};

export default Questions;