import { SettingsType } from "../../../types/Settings.type";
import { useState, useEffect } from "react";
const BindCodes = ({ initialCodes, saveCodes }: { initialCodes: { code: string, bind: string }[], saveCodes: (codes: { code: string, bind: string }[]) => void }) => {
    const [codes, setCodes] = useState<{ code: string, bind: string }[]>(initialCodes || []);

    const saveHandler = () => {
        saveCodes(codes);
    };

    useEffect(() => {
        setCodes(initialCodes);
    }, [initialCodes]);

    const changeCode = (code: string, bind: string) => {
        setCodes(codes.map(c => c.code == code ? { code, bind } : c));
    };

    return <div className="mb-6">
        <div className="label">
            <span className="text-xl">Привязка кода</span>
        </div>
        <div className="flex">
            {codes.map(({ code, bind }) => {
                return (
                    <div key={code} className="mx-2 flex-1">
                        <label className="">
                            <div className="label">
                                <span className="label-text">{code}</span>
                            </div>
                            <input
                                type="text"
                                value={bind}
                                onChange={(e) => changeCode(code, e.target.value)}
                                className="input w-full  input-sm flex-1 input-bordered"
                            />
                        </label>
                    </div>
                );
            })}
        </div>
        <div className=" mt-5 flex justify-end">
            <button
                onClick={saveHandler}
                className="btn btn-primary btn-outline"
            >
                Сохранить
            </button>
        </div>
    </div>

};

export default BindCodes;