import React, { useCallback, useState } from "react";

import Questions from "./Questions/index";
import CurrentStatus from "./CurrentStatus";
import UserContacts from "./UserContacts";
import Location from "./Location";
import UserAuth from "./UserAuth";
import Finish from "./Finish";

const steps: StepType[] = [
  "current-status",
  "user-auth",
  "user-contacts",
  "questions",
  "location",
  "finish",
];

type StepType =
  | "current-status"
  | "user-auth"
  | "user-contacts"
  | "questions"
  | "location"
  | "finish";

interface MainProps {
  isLite: boolean;
}

export default function MainPage({ isLite }: MainProps) {
  const [step, setStep] = useState<StepType>(steps[0]);

  const nextHandler = useCallback(() => {
    let stepIndex = steps.findIndex((s) => step === s);
    let nextStepIndex = stepIndex + 1;
    if (steps[nextStepIndex]) {
      setStep(steps[nextStepIndex]);
    }
  }, [step]);

  return (
    <div className="p-3 pt-2  flex items-center justify-center">

      <div className="w-full sm:w-80 px-2 py-2 overflow-hidden mt-8">
        {step == "current-status" && (
          <CurrentStatus finishHandler={nextHandler} />
        )}
        {step == "user-auth" && <UserAuth finishHandler={nextHandler} />}
        {step == "user-contacts" && (
          <UserContacts finishHandler={nextHandler} />
        )}
        {step == "questions" && (
          <Questions finishHandler={nextHandler} />
        )}
        {step == "location" && <Location finishHandler={nextHandler} />}
        {step == "finish" && <Finish />}
      </div>

    </div>
  );
}
