import { LangType } from "./сonstants";
import { useEffect, useState } from "react";
import axios from "axios";
import { TraitType } from "../../../types/Trait.type";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";

type TraitsProps = {
  lang: LangType;
  mode: string;
  codes: string[];
};

const Traits = ({ lang, mode, codes }: TraitsProps) => {
  const [traits, setTraits] = useState<TraitType[]>([]);

  useEffect(() => {
    axios
      .get(`/traits?mode=${mode}`)
      .then(({ data }: { data: TraitType[] }) => {
        setTraits(data);
      });
  }, [mode]);

  console.log(codes);

  const addTrait = () => {
    axios.post(`/traits`, { mode }).then(({ data }: { data: TraitType[] }) => {
      setTraits([...data, ...traits]);
    });
  };

  const deleteTrait = (base: string) => {
    setTraits(traits.filter((t: TraitType) => t.base != base));
  };

  const traitUnionList = unionMoodTraits(traits);

  return (
    <div className="mt-8">
      <div className="flex flex-col gap-4">
        <div className="collapse collapse-arrow border border-gray-200">
          <input type="checkbox" />
          <div className="collapse-title text-xl  font-medium">Черты</div>
          <div className="collapse-content">
            <div className="flex justify-end">
              <button className="btn btn-primary" onClick={addTrait}>
                Добавить черту
              </button>
            </div>
            <div className="flex flex-col gap-4 mt-4">
              {traitUnionList.map((t: any) => {
                return (
                  <TraitUnionItem
                    traits={t}
                    lang={lang}
                    deleteTrait={deleteTrait}
                    codes={codes}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="mt-8">
  //     <div className="flex justify-between">
  //       <p className="text-xl mb-4">Черты</p>

  //     </div>

  //     {traitUnionList.map((t: any) => {
  //       let key = t[0]?._id;
  //       return (
  //         <TraitUnionItem
  //           key={key}
  //           codes={codes}
  //           lang={lang}
  //           traits={t}
  //           deleteTrait={deleteTrait}
  //         />
  //       );
  //     })}
  //   </div>
  // );
};

const TraitUnionItem = ({
  traits,
  lang,
  deleteTrait,
  codes,
}: {
  traits: TraitType[];
  lang: LangType;
  deleteTrait: (base: string) => void;
  codes: string[];
}) => {
  const initialPositive = traits[0];
  const initialNegative = traits[1];

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [positive, setPositive] = useState<TraitType>(initialPositive);
  const [negative, setNegative] = useState<TraitType>(initialNegative);

  const fetchHandler = (data: TraitType[]) => {
    axios.put("/traits", data).then(() => {
      toast.success("Черта измененна!");
    });
  };

  const saveHandler = () => {
    fetchHandler([positive, negative]);
  };

  const changeValue = (value: string, mood: string) => {
    if (mood == "positive") {
      setPositive((prev: any) => Object.assign({}, prev, { [lang]: value }));
    } else if (mood == "negative") {
      setNegative((prev: any) => Object.assign({}, prev, { [lang]: value }));
    }
  };

  const changeModeCode = (value: string, mood: string) => {
    let setFunction;

    if (mood == "positive") {
      setFunction = setPositive;
    } else if (mood == "negative") {
      setFunction = setNegative;
    } else {
      return;
    }
    setFunction((prev: any) => Object.assign({}, prev, { modeCode: value }));
  };

  const deleteHandler = () => {
    if (window.confirm("Вы действительно хотите удалить черту?")) {
      axios.delete(`/traits?base=${positive.base}`).then(() => {
        deleteTrait(positive.base);
        toast.success("Черта удалена!");
      });
    }
  };

  let positiveName = positive.rus || "Нет названия";
  let negativeName = negative.rus || "Нет названия";

  const crucialChange = (mood: string) => {
    if (mood == "positive") {
      setPositive((prev: any) =>
        Object.assign({}, prev, { isCrucial: !prev.isCrucial })
      );
    } else if (mood == "negative") {
      setNegative((prev: any) =>
        Object.assign({}, prev, { isCrucial: !prev.isCrucial })
      );
    }
  };

  return (
    <div className="card border border-gray-200  overflow-hidden px-4 py-2">
      <div className="w-full flex items-center">
        <div className="flex-1 grid sm:grid-cols-1 md:grid-cols-2">
          <div className="flex items-end m-2">
            <div className="flex-1 w-max">
              <div className="label">
                <span className="label-text">{positiveName} (Поз.)</span>
              </div>
              <input
                type="text"
                value={positive[lang] || ""}
                onChange={(e) => changeValue(e.target.value, "positive")}
                className="input w-full input-sm input-bordered"
              />
            </div>
            <label className="label cursor-pointer flex flex-col ml-3">
              <span className="label-text">Ключе.</span>
              <input
                type="checkbox"
                className="toggle toggle-sm toggle-primary"
                checked={positive.isCrucial}
                onChange={() => crucialChange("positive")}
              />
            </label>
            <div className="flex ml-2 flex-col items-start">
              <div className="label text-sm mb-0">Код</div>
              <select
                className="select select-sm select-primary w-full max-w-xs"
                value={positive.modeCode}
                onChange={(e) =>
                  changeModeCode(e.target.value as string, "positive")
                }
              >
                {codes.map((code) => (
                  <option key={code} value={code}>
                    {code}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex items-end m-2">
            <div className="flex-1">
              <div className="label">
                <span className="label-text">{negativeName} (Нег.)</span>
              </div>
              <input
                type="text"
                value={negative[lang]}
                onChange={(e) => changeValue(e.target.value, "negative")}
                className="input w-full input-sm  input-bordered"
              />
            </div>

            <label className="label cursor-pointer flex flex-col ml-3">
              <span className="label-text">Ключе.</span>
              <input
                type="checkbox"
                className="toggle toggle-sm toggle-primary"
                checked={negative.isCrucial}
                onChange={() => crucialChange("negative")}
              />
            </label>
            <div className="flex ml-2 flex-col items-start">
              <div className="label text-sm mb-0">Код</div>
              <select
                className="select select-sm select-primary w-full max-w-xs"
                value={negative.modeCode}
                onChange={(e) =>
                  changeModeCode(e.target.value as string, "negative")
                }
              >
                {codes.map((code) => (
                  <option key={code} value={code}>
                    {code}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <button
          onClick={deleteHandler}
          className="btn btn-sm btn-error ml-3 btn-square btn-sm btn-outline"
        >
          <Icon fontSize="26px" icon="material-symbols:delete-outline" />
        </button>
        <button
          className="btn btn-sm btn-primary"
          onClick={saveHandler}
          disabled={isSubmitted}
        >
          {isSubmitted ? "Сохранение..." : "Сохранить"}
        </button>
      </div>
    </div>
  );
};

function unionMoodTraits(traits: TraitType[]): [TraitType, TraitType][] {
  let result: any = [];
  let positiveTraits = traits.filter((t: TraitType) => t.mood == "positive");

  positiveTraits.forEach((pt: any) => {
    let negativeTrait = traits.find(
      (t: any) => t.base == pt.base && t.mood == "negative"
    );
    result = [...result, [pt, negativeTrait]];
  });

  return result;
}

export default Traits;
