import { useEffect, useState } from "react";
import LanguageAndMode from "./LanguageAndMode";
import { SettingsType } from "../../../types/Settings.type";
import { langList, LangType } from "./сonstants";
import axios from "axios";
import BindCodes from "./BindCodes";
import { toast } from "react-toastify";
import Questions from "./Questions";
import Traits from "./Traits";
import ResultMask from "./ResultMask";

const Settings = () => {
  const [settings, setSettings] = useState<SettingsType[]>([]);
  const [settingId, setSettingId] = useState<string | null>(null);
  const [lang, setLang] = useState<LangType>(langList[0]);

  useEffect(() => {
    axios.get("/settings").then(({ data }: { data: SettingsType[] }) => {
      setSettings(data);
      setSettingId(data[0]._id);
    });
  }, []);

  const saveCodes = (codes: { code: string; bind: string }[]) => {
    axios
      .put(`/settings`, { _id: settingId, codesBind: codes })
      .then(({ data }: { data: SettingsType }) => {
        setSettings(settings.map((s) => (s._id == settingId ? data : s)));
        toast.success("Коды сохранены");
      });
  };

  if (!settingId) return null;

  const setting = settings.find((s) => s._id === settingId)!;

  const codes = setting.codes.split(" ");

  return (
    <div>
      <LanguageAndMode
        lang={lang}
        setLang={setLang}
        settings={settings}
        settingId={settingId}
        setSettingId={setSettingId}
      />
      <Questions lang={lang} />
      {/* <div className="divider"></div> */}
      <BindCodes initialCodes={setting.codesBind} saveCodes={saveCodes} />
      <ResultMask mode={setting.mode} />
      <Traits codes={codes} lang={lang} mode={setting.mode} />
    </div>
  );
};

export default Settings;
