import React, { useState } from "react";
import { CustomInput } from "../../components/UI/CustomInput";
import InputMask from "react-input-mask";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useUser } from "../../context/userContext";

import { useTranslation } from "react-i18next";

interface FormValues {
  name: string;
  phone: string;
  sex: string;
}

interface UserContactsProps {
  finishHandler: Function;
}

// finishHandler

const UserContacts: React.FC<UserContactsProps> = ({ finishHandler }) => {
  const initialValues: FormValues = { name: "", phone: "", sex: "male" };

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string().required(t("field.requiredFieldError")),
    phone: Yup.string(),
    sex: Yup.string().oneOf(["male", "female"]).required(t("field.requiredFieldError")),
  });

  const user = useUser();

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    user.updateUser("name", values.name);
    user.updateUser("phone", values.phone);
    user.updateUser("sex", values.sex);
    finishHandler();
    actions.setSubmitting(false);
  };

  return (
    <>
      <div className="">
        <p className="text-2xl text-center font-bold mb-6">{t("userContacts.title")}</p>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <CustomInput
                    name="name"
                    label={t("userContacts.nameLabelInput")}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    errorMessage={
                      touched.name && errors.name ? errors.name : ""
                    }
                  />
                  <CustomInput
                    name="phone"
                    label={t("userContacts.phoneLabelInput")}
                    type="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    errorMessage={
                      touched.phone && errors.phone ? errors.phone : ""
                    }
                  />
                  <div className="flex ">
                    {/* <p>Пол</p> */}
                    <div className="flex flex-col items-start mt-4">
                      <div className="">
                        <label className="label cursor-pointer">
                          <input type="radio" name="sex" className="radio checked:bg-primary"  value="male" onChange={handleChange} checked={values.sex === "male"} />
                          <span className="label-text ml-2 text-base font-bold">
                            {t("userContacts.male")}
                          </span>
                        </label>
                      </div>
                      <div className="">
                        <label className="label cursor-pointer">
                          <input type="radio" name="sex" className="radio checked:bg-primary" onChange={handleChange} value="female" checked={values.sex === "female"} />
                          <span className="label-text ml-2 text-base font-bold">
                            {t("userContacts.female")}
                          </span>
                        </label>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="mt-5">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary w-full btn-large btn-outline text-lg py-3 h-auto"
                  >
                    {t("button.next")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UserContacts;
