import { useState, useEffect } from "react";
import { TraitMoodInterface, LangType } from "./types";
import getTextAndSubtext from "./getTextAndSubtext";

// Интерфейс

export const MoodQuestion: React.FC<{
  initTairs: TraitMoodInterface[];
  question: string;
  setFinish: Function;
  setPercent: Function;
  lang: LangType;
}> = ({ initTairs, question, setFinish, setPercent, lang }) => {
  // Начальное состояние
  const [traits, setTraits] = useState<TraitMoodInterface[]>(initTairs);
  const [cycleCount, setCycleCount] = useState<number>(1);
  const [answers, setAnswers] = useState<
    {
      rus: string;
      geo: string;
      eng: string;
      _id: string;
      modeCode: string;
    }[]
  >([]);

  useEffect(() => {
    nextRound();
  }, [traits]);

  // Обработчик выбора черты
  const selectHandler = (_id: string) => {
    // ставим выбранную черту в скрытое состояние
    let updatedTraits = traits.map((trait) =>
      trait._id === _id ? { ...trait, hide: true } : trait
    );
    // ставим не выбранные черты в состояние добавления очков
    updatedTraits = updatedTraits.map((trait) => {
      let some = answers
        .filter((a) => a._id !== _id)
        .some((a) => a._id === trait._id);
      return some
        ? {
            ...trait,
            score: Math.min(trait.score + 1, cycleCount),
            cycle: cycleCount,
          }
        : trait;
    });

    // const updatedTraits = traits.map((trait) => {
    //   if (trait._id === _id) {
    //     // Скрываем выбранную черту
    //     return { ...trait, hide: true };
    //   } else if (!trait.hide) {
    //     // Увеличиваем очки остальных видимых черт
    //     const maxScore = getCycleLimit(cycleCount);
    //     const newScore = Math.min(trait.score + 1, maxScore);
    //     return { ...trait, cycle: cycleCount, score: newScore };
    //   }
    //   return trait;
    // });

    console.log(updatedTraits, "updatedTraits");
    setTraits(updatedTraits);

    // console.log(
    //   updatedTraits.filter((t) => !t.hide).map((t) => t.cycle),
    //   "updatedTraits"
    // );
    // Проверка: завершился ли текущий круг
    // если хотя бы одна черта не в этом круге, то увеличиваем круг
    const leftInThisCycle = updatedTraits
      .filter((t) => !t.hide)
      .some((t) => t.cycle !== cycleCount);

    if (!leftInThisCycle) {
      setCycleCount(cycleCount + 1);
    }

    // if (leftInThisCycle.length > 0) {
    //   // Если есть ещё достаточно черт для следующего круга, ничего не делаем
    //   return;
    // } else {
    //   setCycleCount(cycleCount + 1);
    // }

    // Завершение текущего круга
    // Увеличиваем счётчик кругов только в этом месте
  };

  // Логика следующего раунда
  const nextRound = () => {
    // Оставляем только видимые черты
    let visibleTraits = traits.filter(filterVisibleTraits);

    if (visibleTraits.length < 5) {
      // Завершение, если осталось меньше 5 черт
      let finishTraits = [...traits];
      finishTraits = finishTraits.map((t) => {
        if (visibleTraits.some((vt) => vt._id == t._id)) {
          return {
            ...t,
            score: t.score + 1,
          };
        }
        return t;
      });
      console.log(finishTraits, "finishTraits");
      setFinish([...finishTraits]);
      setPercent(100);
      return;
    }

    // Сортируем черты
    visibleTraits = shuffleArray(visibleTraits); // Перемешиваем случайно
    visibleTraits.sort((a, b) => a.score - b.score); // Сначала черты с меньшими очками

    // Отбираем нужные черты
    const selectedTraits = sortByMoodCode(
      visibleTraits
    ) as TraitMoodInterface[];

    // Обновляем состояние ответов
    setAnswers(selectedTraits);
  };

  // Фильтр видимых черт
  const filterVisibleTraits = (trait: TraitMoodInterface) => !trait.hide;

  // Расчёт процента выполнения
  useEffect(() => {
    const percentage = calculatePercentage(
      traits.filter(filterVisibleTraits).length - 4,
      traits.length - 4
    );
    setPercent(percentage);
  }, [traits]);

  // Функция перемешивания массива
  function shuffleArray(array: TraitMoodInterface[]): TraitMoodInterface[] {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }

  // Сортировка по MoodCode
  const sortByMoodCode = (list: TraitMoodInterface[]): TraitMoodInterface[] => {
    // Группируем черты по MoodCode
    const uniqueModeCodes = Array.from(new Set(list.map((t) => t.modeCode)));
    const groupedByCode: { [key: string]: TraitMoodInterface[] } = {};

    uniqueModeCodes.forEach((code) => {
      groupedByCode[code] = list
        .filter((trait) => trait.modeCode === code)
        .sort((a, b) => a.score - b.score)
        .sort((a, b) => (a.cycle || 0) - (b.cycle || 0));
    });

    // Собираем результат, добавляя по одному элементу из каждой группы
    // если цикл элемента меньше текущего круга, то добавляем
    // если не остается меньше текущего круга, то добавляем все черты этого круга

    // сортируем по коду а потом по циклу
    let selectedTraits: TraitMoodInterface[] = [];
    for (const traitsGroup of Object.values(groupedByCode)) {
      if (traitsGroup.length) {
        selectedTraits.push(traitsGroup[0]);
      }
    }

    // Добавляем оставшиеся черты, если их недостаточно
    const remainingTraits = list.filter(
      (trait) => !selectedTraits.some((sel) => sel._id === trait._id)
    );
    selectedTraits = [
      ...selectedTraits,
      ...remainingTraits.slice(0, 5 - selectedTraits.length),
    ];

    return shuffleArray(selectedTraits); // Перемешиваем итоговый список
  };

  // Расчёт процента выполнения
  function calculatePercentage(current: number, total: number): number {
    return 100 - (current / total) * 100;
  }

  return (
    <div>
      <div className="pt-2">
        <p className="text-xl font-bold text-center">{question}</p>
        <div className="mt-6">
          {answers.map((a) => {
            let { text, subtext } = getTextAndSubtext(a[lang] || "");

            return (
              <button
                key={a._id}
                onClick={() => selectHandler(a._id)}
                className={`btn  flex flex-col btn-outline btn-primary btn-block w-full mb-3 btn-large text-lg py-3 h-auto`}
              >
                <span className="inline">{text}</span>
                {!!subtext ? (
                  <span className="text-sm text-base-content font-extralight">
                    {`(${subtext})`}
                  </span>
                ) : (
                  ""
                )}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

function shuffleArray(array: TraitMoodInterface[]): TraitMoodInterface[] {
  let arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

const filterHideCallback = (t: TraitMoodInterface) => {
  return !t.hide;
};

function calculatePercentage(x: number, max: number): number {
  return 100 - (x / max) * 100;
}

const sortByMoodCode = (list: TraitMoodInterface[]) => {
  let arr = [...list];
  let modeCodeList = list.map((t) => t.modeCode);
  let uniqueModeCodeList: string[] = Array.from(new Set(modeCodeList));

  let codeObj: { [key: string]: TraitMoodInterface[] } = {};

  uniqueModeCodeList.forEach((code: string) => {
    codeObj[code] = arr
      .filter((t) => t.modeCode == code)
      .sort((a, b) => a.score - b.score);
  });

  let result: TraitMoodInterface[] = [];

  for (let [_, value] of Object.entries(codeObj)) {
    if (value.length) {
      result.push(value[0]);
    }
  }

  let notInResult = arr.filter((it) => {
    let inResult = result.some((r) => r._id == it._id);
    return !inResult;
  });
  notInResult.sort((a, b) => a.score - b.score);

  let addedCount = 5 - result.length;

  if (addedCount) {
    result = [...result, ...notInResult.slice(0, addedCount)];
  }

  result.sort(() => (Math.random() > 0.5 ? 1 : -1));

  return result;
};
