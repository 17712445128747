import { useState } from "react";

import Settings from "./Settings";
import Results from "./Results";

const Admin = () => {
    const [tab, setTab] = useState<'settings' | 'results'>('settings');
    return <div className="container mx-auto p-4">

        <h2 className="text-2xl font-bold">Админ панель</h2>

        <div className="flex items-center">
            <div className="flex mt-4">
                {[{
                    name: 'Настройки',
                    value: 'settings'
                }, {
                    name: 'Результаты',
                    value: 'results'
                }].map((t) => (
                    <button onClick={() => setTab(t.value as 'settings' | 'results')} className={`${tab == t.value ? '' : 'btn-outline'} btn btn-primary px-4 py-2 rounded-md mr-2`}>{t.name}</button>
                ))}
            </div>
        </div>

        <div className="divider"></div>
        <div className="mt-4">
            {tab == 'settings' && <Settings />}
            {tab == 'results' && <Results />}
        </div>
    </div>;
};

export default Admin;
